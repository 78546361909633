/* Colors VARIABLE */
@import 'Fonts.scss';

$fs-9: 9px ;
$fs-11: 11px ;
$fs-12: 12px ;
$fs-13: 13px ;
$fs-14: 14px ;
$fs-16: 16px ;
$fs-18: 18px ;
$fs-20: 20px ;
$fs-24: 24px ;
$fs-32: 32px ;
$fs-26: 26px ;

.fs-9 {
    font-size: $fs-9;
}
.fs-11 {
    font-size: $fs-11;
}
.fs-12 {
    font-size: $fs-12;
}
.fs-13 {
    font-size: $fs-13;
}
.fs-14 {
    font-size: $fs-14;
}
.fs-16 {
    font-size: $fs-16;
}
.fs-18 {
    font-size: $fs-18;
}
.fs-20 {
    font-size: $fs-20;
}
.fs-24 {
    font-size: $fs-24;
}
.fs-32 {
    font-size: $fs-32;
}

.fs-26 {
    font-size: $fs-26;
}


// Colors
$Primary: #0B6CF9;
$Blue: #1E7AFF;
$Hawkesblue: #BFD9FF;
$Zambezi: #606060;
$Black: #231F20;
$Alice-Blue: #EEF5FF;
$Snowy-Mint: #DCFFCF;
$Forest-Green: #289A00;
$Whisper: #EBEBEB;
$Cosmos: #FFCFCF;
$Red: #F84F4F;
$Dark-Gray: #A4A4A4;
$White-Smoke: #F3F3F3; 
$Pink: #FF8080;
$Celadon: #B1FFB6;
$Bright-Grey: #525457;
$Primary: #0B6CF9;
$Blue: #0B7AF9;
$Zambezi: #6E6E6E;
$Black: #231F20;
$Black2: #171717;
$Dark-Gray: #A4A4A4;
$White-Smoke: #F9F9F9; 
$White: #FFFFFF;
$Matterhorn: #555555;
$Eclipse: #3B3B3B;
$Nobel: #9E9797;
$DimGray: #656565;
$Corduroy: #3E4140;
$Solitude1: #E4EFFF;
$Whisper: #E4E4E4;
$Solitude: #EFF1F3;
$Gainsboro: #DFDFDF;
$Gainsboro2: #D8D8D8;
$Green: #3FD98B;
$AustralianMint: #EAFFA2;
$LightCyan: #D1FFFE;
$SandyBeach: #FFDEB4;
$YellowSea: #F09E35;
$PersianGreen: #00A9A6;
$Citrus: #A1CF00;
$HawkesBlue: #CFD7E2;
$PineGreen: #056F6D;
$SaddleBrown: #8B5106;
$IconGray: #8B8989;
$SunsetOrange: #F54C4C;
$Orange: #EB9037;
$DarkGreen: #19AB02;


.solitude_Bg{
    background: $Solitude !important;
}
.color-Bri-Grey{
    color: $Bright-Grey;
}
.color-hawkesblue {
    color: $Hawkesblue;
}
.color-Zambezi{
    color: $Zambezi !important;
}
.color-Black{
    color: $Black;
}
.color-Alice-Blue{
    color: $Alice-Blue;
}
.primary-text {
    color: $Primary !important;
}
.Green-text{
    color: $Forest-Green !important;
}
.Red-text{
    color: $Red !important;
}
.Gray-text{
    color: $Dark-Gray !important;
}

// Background color:-
.Zambezi-bg{
    background-color: $Zambezi;
}
.Smoke-bg{
    background-color: $White-Smoke !important;
}
.Gray-bg{
    background-color: $Dark-Gray !important;
}
.Cosmos-bg{
    background-color: $Cosmos;
}
.Snowy-bg{
    background-color: $Snowy-Mint !important;
}
.alice-blue-bg{
    background-color: $Alice-Blue;
}
.black-bg{
    background-color: $Black;
}
.primary-bg {
    background-color: $Primary !important;
}
.Whisper-bg{
    background-color: $Whisper !important;
}
.Blue-bg{
    background-color: $Blue;
}
.Celadon-bg{
    background-color: $Celadon !important;
}

.Red-bg{
    background-color: $Red !important;
}


// Fonts
$OpSa-Bold: OpenSans-Bold !important;
$OpSa-SemiBold: OpenSans-SemiBold !important;
$OpSa-Reg: OpenSans-Regular !important;
$OpSa-Med: OpenSans-Medium !important;
$OpSa-ExBol: OpenSans-ExtraBold !important;

$Cat-Bold: Catamaran-Bold !important;
$Cat-Med: Catamaran-Medium !important;

.OpSa-Bold {
    font-family: $OpSa-Bold;
}
.OpSa-SemiBold {
    font-family: $OpSa-SemiBold;
}
.OpSa-Reg {
    font-family: $OpSa-Reg;
}

.OpSa-Med {
    font-family: $OpSa-Med;
}

.OpSa-ExBol {
    font-family: $OpSa-ExBol;
}

.Cat-Bold {
    font-family: $Cat-Bold;
}

.Cat-Med {
    font-family: $Cat-Med;
}

$Dm-Bold: DMSans-Bold ;
$Dm-Med: DMSans-Medium ;
$Dm-Reg: DMSans-Regular ;
$Dm-BoldItalic: DMSans-BoldItalic ;

.Dm-Bold {
    font-family: $Dm-Bold !important ;
    font-weight: 900 !important;
}
.Dm-Med {
    font-family: $Dm-Med !important;
    font-weight: 300 !important;
}
.Dm-Reg {
    font-family: $Dm-Reg !important;
}
.Dm-BoldItalic {
    font-family: $Dm-BoldItalic !important;
}
