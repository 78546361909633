/* Font Family */
@font-face {
    src: url(/assets/Fonts/OpenSans-Bold.ttf);
    font-family: OpenSans-Bold;
}
@font-face {
    src: url(/assets/Fonts/OpenSans-SemiBold.ttf);
    font-family: OpenSans-SemiBold;
}
@font-face {
    src: url(/assets/Fonts/OpenSans-Regular.ttf);
    font-family: OpenSans-Regular;
}

@font-face {
    src: url(/assets/Fonts/OpenSans-Medium.ttf);
    font-family: OpenSans-Medium;
}

@font-face {
    src: url(/assets/Fonts/OpenSans-ExtraBold.ttf);
    font-family: OpenSans-ExtraBold;
}

@font-face {
    src: url(/assets/Fonts/Catamaran-Bold.ttf);
    font-family: Catamaran-Bold;
}

@font-face {
    src: url(/assets/Fonts/Catamaran-Medium.ttf);
    font-family: Catamaran-Medium;
}

/* ====================== Fonts End ====================== */
