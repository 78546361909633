@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?h7nx19');
  src:  url('fonts/icomoon.eot?h7nx19#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?h7nx19') format('truetype'),
    url('fonts/icomoon.woff?h7nx19') format('woff'),
    url('fonts/icomoon.svg?h7nx19#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-lambda:before {
  content: "\e917";
}
.icon-align-center:before {
  content: "\e900";
}
.icon-chat-arrow-grow:before {
  content: "\e901";
}
.icon-Component-11--3:before {
  content: "\e902";
  color: #606060;
}
.icon-delete:before {
  content: "\e903";
}
.icon-download:before {
  content: "\e904";
}
.icon-Group-616 .path1:before {
  content: "\e907";
  color: rgb(31, 31, 31);
}
.icon-Group-616 .path2:before {
  content: "\e908";
  margin-left: -1em;
  color: rgb(28, 28, 28);
}
.icon-man:before {
  content: "\e909";
}
.icon-Path-4:before {
  content: "\e90a";
}
.icon-Path-13:before {
  content: "\e90b";
}
.icon-Polygon-1:before {
  content: "\e90c";
}
.icon-right-icon:before {
  content: "\e90d";
}
.icon-search:before {
  content: "\e90e";
}
.icon-cloud:before {
  content: "\e915";
}
.icon-layers:before {
  content: "\e911";
}
.icon-star-full:before {
  content: "\e916";
}
.icon-cancel:before {
  content: "\e912";
}
.icon-cancel-circle:before {
  content: "\e913";
}
.icon-cross:before {
  content: "\e914";
}
.icon-cogs:before {
  content: "\e905";
}
.icon-equalizer:before {
  content: "\e906";
}
.icon-equalizer1:before {
  content: "\e90f";
}
.icon-wrench:before {
  content: "\e910";
}
.icon-up_arrow:before {
  content: "\ea3a";
}
.icon-down_arrow:before {
  content: "\ea3e";
}
.icon-clock:before {
  content: "\e913"; /* Example unicode */
}

.custom-switch-non-fade .form-check-input:disabled {
  opacity: 1 !important;
  pointer-events: none;
}

.custom-switch-non-fade .form-check-input:disabled:checked {
  background-color: #0d6efd !important; /* Blue color for checked state */
  border-color: #0d6efd !important;     /* Ensure border color stays the same */
}

.custom-switch-non-fade .form-check-input:disabled:not(:checked) {
  background-color: #dee2e6 !important; /* Gray color for unchecked state */
  border-color: #adb5bd !important;     /* Ensure border color stays the same */
}
