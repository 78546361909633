@import "Variable.scss";
@import '~leaflet/dist/leaflet.css'; // sass
@import "react-loading-skeleton/dist/skeleton.css";

body {
  overflow: hidden
}

/* mix style */
.w-65 {
  width: 65% !important;
}

.w-45 {
  width: 45% !important;
}

.box-icon {
  width: 40px;
  height: 40px;
  background-color: #F3F3F3;
  border-radius: 5px;
  padding: 8px;
}

.box-sidebar {
  width: 40px;
  height: 40px;
  background-color: #1E7AFF;
  border-radius: 5px;
  padding: 8px;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbaractive {
  background-color: #F3F3F3 !important;

  i {
    color: $Primary;
  }
}

.vh-75 {
  height: 75%;
}

.padding-btn {
  padding: 0rem 0.6rem 0.2rem 0.6rem !important;
  border-radius: 5px !important;
}

.border-smoke {
  border: 1px solid $White-Smoke !important;
}

.border-btn {
  border: 1px solid $Primary !important;
}

.card-Shadow {
  box-shadow: 0 4px 4px rgba($color: #9A9A9A, $alpha: 0.16) !important;
  border-radius: 5px;
}

.box-small {
  width: 30px;
  height: 30px;
  display: flex;
  cursor: pointer;
}

.usage-date {
  background-color: $Solitude;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 0.4rem 0.5rem;

  ul {
    display: flex;
    padding: 0 !important;
    margin: 0 !important;

    li {
      list-style: none;
      background-color: $White;
      font-family: $Dm-Med;
      font-size: $fs-12;
      margin-right: 0.2rem;
      padding: 0.4rem 0.5rem;
      border-radius: 5px;
      color: $Zambezi;
    }
  }
}


/* Login Page */
.posi-icons {
  .pa-right {
    right: 4rem !important;
    top: 2rem;
  }

  .pa-left {
    left: 2rem !important;
  }
}

.form-heading {
  .position-right {
    right: 0;
  }

  .position-bottom {
    bottom: 4rem;
    left: 7rem;
  }

  h1 {
    font-size: 31px;
    font-family: $OpSa-Bold;
  }

  P {
    font-size: 12px;
    font-family: $OpSa-Bold;
  }

  form {
    input {
      background-color: transparent;
      border: 1px solid #ffffff !important;
      color: #bfd9ff;
      font-size: 12px;
      font-family: $OpSa-Bold;
      padding: 10px;
      border-radius: 5px !important;
    }

    input:focus {
      background-color: transparent;
      color: #bfd9ff;
      font-size: 12px;
      font-family: $OpSa-Bold;
    }

    input::placeholder {
      background-color: transparent;
      color: #bfd9ff;
      font-size: 12px;
      font-family: $OpSa-Bold;
    }

    button {
      color: $Primary;
      font-family: OpenSans-Medium;
      font-size: 14px;
    }

    button:hover {
      color: $Primary;
    }
  }
}

// Header:-
header {
  // min-height: 8.4vh;
  position: fixed;
  top: 0;
  background-color: #ffffff;
  width: 100%;
  z-index: 999;

}


// Sidebar:-
.wrapper {
  display: flex;
  min-height: calc(100vh - 8.4vh);
  margin-top: 3.2rem;

  .header-sticky {
    position: sticky;
    height: 50px;
    top: 9px;
    background: #F9F9F9;
    z-index: 100;
  }

  .sidebar-nav {
    min-width: min-content;
    height: 100vh;
    background-color: #0B6CF9;
    padding: 20px 8px;
    position: fixed;
    left: 0;
    top: 0;
    padding: 5rem .5rem 1rem;
  }


  .main-body {
    margin-left: 4rem;
    padding: 10px;
    width: calc(100% - 5%);
    min-height: 100%;
    background-color: #F9F9F9;
    height: 95vh;
    overflow-y: scroll;
  }
}

// Control-Panel:-
.small-cards {
  box-shadow: 0 4px 4px rgba($color: #9A9A9A, $alpha: 0.16);

  h5 {
    font-family: $OpSa-Bold;
    font-size: $fs-24;
  }

  p {
    font-size: $fs-12;
    font-family: $OpSa-Bold;

    span {
      font-family: $OpSa-Bold;
      font-size: $fs-12;
    }
  }

  button {
    background-color: $Snowy-Mint;
    border-radius: 5px;
    border: 0 !important;
    font-size: $fs-12;
    color: $Forest-Green;
    font-family: $OpSa-Bold;
  }

  button:hover {
    color: $Forest-Green;
    background-color: $Snowy-Mint;
  }

  button:focus {
    box-shadow: 0 !important;
  }
}

.text-heading-cont {
  .icon {
    .icon-right-icon {
      width: 45px;
      height: 45px;
      border-radius: 5px;
      background-color: $Whisper;
      display: flex;
      justify-content: center;
    }
  }

  .text-heading {

    p {
      font-family: $OpSa-Bold;
      margin: 0 !important;
    }


  }
}

.greenbg {
  background-color: $Forest-Green !important;
  color: white !important;
  transition: 0.3s ease-in-out;
}

.Pink-bg {
  background-color: $Pink;
  color: white !important;
  transition: 0.3s ease-in-out;
}


// Setting:-
.plan-cards {
  .card {
    border: 0 !important;
    box-shadow: 0 4px 4px rgba($color: #9A9A9A, $alpha: 0.16) !important;
    position: relative;

    .card-title {
      margin: 0 !important;

      h6 {
        font-size: $fs-20;
        font-family: $Cat-Bold;
      }

      p {
        font-size: $fs-16;
        font-family: $Cat-Bold;
      }
    }

    .card-text {
      p {
        font-size: $fs-12;
        font-family: $Cat-Med;
        color: $Zambezi;
      }
    }

    .edit-box {
      top: 2.8rem;
      right: 1.5rem;
      border-radius: 5px;
      border: 1px solid #E1E1E1;
      background-color: #FCFCFC;
      padding: 10px 10px 0 10px;
      width: 40%;

      p {
        font-size: $fs-14;
        font-family: $OpSa-Reg;
        color: $Black;
      }
    }
  }
}

.sidebox::-webkit-scrollbar {
  background-color: transparent;
}

.sidebox {
  .flagdata {
    .keydata {
      flex-wrap: unset;
    }
  }
}



.sidebox {
  right: 0;
  transition: transform 0.5s ease-in-out;
  z-index: 1000;
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  box-shadow: -4px 0 4px rgba($color: #9A9A9A, $alpha: 0.16);
  padding: 20px;
  overflow-y: scroll;

  form {
    .form-label {
      font-family: $OpSa-Bold;
      font-size: $fs-12;
      color: $Black;
    }

    .form-control {
      font-family: $OpSa-Bold;
      font-size: $fs-12;
      color: #A4A4A4;
      padding: 10px;
      border-radius: 5px;
    }

    .table-bar table {
      width: 100%;

      thead {
        background-color: #F2F2F2;
        border-radius: 5px !important;

        tr th {
          padding: 10px;
          font-size: $fs-12;
          font-family: $OpSa-Reg;
          color: #606060 !important;

        }

        tr th:nth-child(3) {
          width: 50%;
        }
      }

      tbody {
        tr td {
          padding: 10px;
          font-size: $fs-14;
          font-family: $OpSa-Reg;

          color: $Black;
        }
      }
    }
  }
}

.table-bar table {
  width: 100%;
  position: relative;

  thead {
    background-color: #F2F2F2;
    border-radius: 5px !important;
    position: sticky;
    top: -1px;
    color: white;
    z-index: 0;


    tr th {
      padding: 10px;
      font-size: $fs-12;
      font-family: $OpSa-Reg;
      color: #606060 !important;
      height: 40px;
      font-weight: 1200;
    }
  }

  tbody {
    tr td {
      padding: 10px;
      font-size: $fs-14;
      font-family: $OpSa-Bold;
      color: $Black;

      Button {
        font-size: $fs-14;
        font-family: $OpSa-Bold;
      }

      Button:focus {
        box-shadow: 0 !important;
      }
    }
  }
}

.border-dash {
  border: 1px dashed !important;
  background-color: transparent !important;
  border-color: $Black !important;
  color: $Black !important;
}

.dropdown {
  button {
    background-color: transparent !important;
    border: 0 !important;
  }

  button:after {
    display: none;
  }

  .dropdown-menu {
    padding: 10px 10px 0 10px;
  }
}

.nav-pills .nav-link.active {
  background-color: $Alice-Blue !important;
  color: $Primary !important;
}

// Customer:-
.search-box {

  .form-control,
  .input-group-text,
  .form-select {
    background-color: transparent !important;
    color: $Black;
    border: 0 !important;
  }

  .form-control,
  .form-select {
    font-size: $fs-12;
    font-family: $OpSa-Bold;
    color: $Black;
  }

  .form-control::placeholder {
    font-size: $fs-12;
    font-family: $OpSa-Bold;
    color: $Black;
  }
}

.form-control:focus,
.btn:focus,
.form-select:focus {
  box-shadow: 0 0 0 !important;
}

.sidebox {
  overflow: auto;
}

.icon-Component-11--3 {
  color: grey;
}

.plans-cards-shadow {
  box-shadow: 0 3px 5px rgba($color: #000000, $alpha: 0.16);
}

.overflow-x {
  overflow-x: auto;
  min-height: 400px;
}

.status-btn {
  font-size: 12px !important;
  padding: .25rem !important;
  margin-top: .25rem !important;
}

.service-card {
  cursor: pointer;
}

button.status-btn {
  font-size: 12px;
  border: 0 !important;
  font-size: 12px;
  font-family: OpenSans-Bold !important;
  padding: 0.2rem 0.7rem;
  border-radius: 5px !important;
  border: 1px solid #0d6efd !important;
  color: #fff;
}

button.status-btn.active {
  background-color: #0d6efd;
  border: 1px solid #0d6efd !important;
}

button.status-btn.inactive {
  background-color: #f84f4f;
  border: 1px solid #f84f4f !important;
}

.pointer {
  cursor: pointer;
}

.activebg {
  background: $Primary !important;
  color: #ffffff !important;
}

.box-small {
  width: 33.3%vh;
  height: 33.3%vw;
  cursor: pointer;
}

// .cards-dashboard{
//   background-color: $Solitude !important;
//   border: 0 !important;
//   border-radius: 10px !important;
// }



.delete-true {
  width: 5px;
  height: 5px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
  text-align: end;
  margin: auto;
}

.delete-false {
  width: 5px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
  text-align: end;
  margin: auto;
}

.custom-table-Logs {

  width: 100%;
  position: relative;


  .api-usaged-table {
    background-color: #F2F2F2;
    border-radius: 5px !important;
    position: sticky;
    text-align: center;
    top: 67px;
    color: rgb(7, 6, 6);
    z-index: 0;

    .text-uppercase th {
      padding: 10px;
      font-size: $fs-12;
      font-family: $OpSa-Reg;
      color: #606060 !important;
      height: 40px;
    }
  }

}


.datatablefooter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center
}

.datatableshowing {
  color: #6b6c72;
}

.pagination {
  display: flex;
  justify-content: space-between;
  list-style: none;
  cursor: pointer;

  & a {
    padding: 10px;
    border: 1px solid transparent;
    color: #505050;

    &:hover {
      color: #3965ce !important;
      border: 1px solid #cacaca;
      background-color: #e3eaf3;
    }
  }
}


.paginationLink {
  font-weight: bold;

  &:hover {
    background-color: red;
  }
}

.paginationActive {
  & a {
    color: #505050;
    border: 1px solid #cacaca;
    background: linear-gradient(to bottom, #fff 0, #eaeaea 100%);
  }
}

.paginationDisabled {
  & a {
    color: #cacaca;
  }
}

.managebtn {
  color: pink;
  background-color: red;
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.tablestky table {
  width: 100%;
  position: relative;

  thead {
    background-color: #F2F2F2;
    border-radius: 5px !important;
    position: sticky;
    text-align: center;
    top: 40px;
    color: white;
    z-index: 0;

    tr th {
      padding: 10px;
      font-size: $fs-12;
      font-family: $OpSa-Reg;
      color: #606060 !important;
      height: 40px;
    }
  }

  tbody {
    tr td {
      padding: 10px;
      font-size: $fs-14;
      font-family: $OpSa-Bold;
      text-align: center;
      color: $Black;

      Button {
        font-size: $fs-14;
        font-family: $OpSa-Bold;
      }

      Button:focus {
        box-shadow: 0 !important;
      }
    }
  }
}

.icon-down_arrow:after {
  font-size: 20px;


}

.icon-down_arrow {
  transition: transform 0.3s ease-in-out;
  // background: red;

}

.rotate-180 {
  transform: rotate(180deg);
}


.Cred {
  padding-top: .6rem;
}

// .tablecss {
//   overflow-y: scroll;
//   overflow-x: auto;
//   height: 56vh;
// }

.tablecss table {
  position: relative;
}

.tablecss thead {
  padding: 10px;
  font-size: $fs-12;
  font-family: $OpSa-Reg;
  text-align: center;
  z-index: 90;
  color: #606060 !important;
  background-color: #F2F2F2;
  height: 40px;
  position: sticky;
  top: 100px;
}

.tablecss table {
  width: 100%;
  position: relative;

  thead {
    padding: 10px;
    font-size: $fs-12;
    font-family: $OpSa-Reg;
    text-align: center;

    color: #606060 !important;
    background-color: #F2F2F2;
    height: 40px;
    position: sticky;
    top: 55px;


    tr th {
      font-size: $fs-12;
      font-family: $OpSa-Reg;
      text-align: center;

      color: #606060 !important;
    }
  }

  tbody {
    tr td {
      padding: 10px;
      font-size: $fs-14;
      font-family: $OpSa-Bold;
      text-align: center;

      color: $Black;

      Button {
        font-size: $fs-14;
        font-family: $OpSa-Bold;
      }

      Button:focus {
        box-shadow: 0 !important;
      }
    }
  }
}


.higherZIndex {
  z-index: 90 !important;
}

.dropDownIndex {
  z-index: 1001 !important;
  width: 13rem;
}

.flow {
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.bgOverflow {
  background: #1616169c;
}

.fixed {
  overflow: hidden;
  background-color: #0d6efd;
  color: white;
  position: sticky;
  /* Set the navbar to fixed position */
  top: 0;
  /* Position the navbar at the top of the page */
  width: 100%;
  /* Full width */
  z-index: 2;
}

.datePicker {
  width: 100%;
}

.onscreen-usage {
  display: block !important;
  height: 93vh;
  overflow-y: scroll;
  position: fixed;
  width: 14%;
}

.graph-table {
  border: 0;
}

.graph-table tr td {
  border: 0;
}

.graph-box {
  display: flex;
  position: relative;

}

.service {
  position: absolute;
  width: 100%;
  height: 100%;
  color: #fff;
  display: table;
  text-align: center;
}

.service .stats {
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;
  white-space: nowrap;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;

}

.service .stats-box {
  display: table-cell;
  vertical-align: middle;
}

.service .stats span {
  max-width: 90%;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  margin: 0 auto;
}

.service .stats-num {
  font-size: 13px;
  font-weight: 500;
}


.green {
  background-color: #2e901f;
  display: inline-block;
  height: 50px;
}

.red {
  background-color: #ce0d0d;
  display: inline-block;
  height: 50px;
}

.grey {
  background-color: #aaa;
  display: inline-block;
  width: 100%;
  height: 50px;
}

// client 
.client-sec .header-sticky {
  position: sticky;
  height: 100%;
  top: 6px;
  background: #F9F9F9;
  z-index: 100;
  padding-bottom: 10px;
}

.markercluster-map {
  height: 90vh;
}